export const searchData = [
  {
    "title": "eNets Android",
    "description": `eNETS Android SDK is provided as an AAR library. Below are the steps that detail the setup for the SDK on android. Followings permissions must be added in the merchant apps Android Manifest...`,
    "path" : "/docs/android/"
  },
  {
    "title": "eNets iOS",
    "description": "The eNETS iOS SDK can be downloaded from...",
    "path": "/docs/ios/"
  },
  {
    "title": "eNETS Integration Guide",
    "description": "Integrating with the new eNETS payment gateway is fast and fun, be it from your mobile app or website. An ever-growing list of payment options makes sure that you’re integrating with a robust and comprehensive payment gateway...",
    "path": "/docs/overview/"
  },
  {
    "title": "eNets Web",
    "description": "eNETS can be integrated by a variety of ways on a website...",
    "path": "/docs/web/"
  },
  {
    "title": "Flow 4 (Server-to-Server)",
    "description": "This flow is for Merchants, who wish to capture credit cards directly, which also subjects the merchant to PCI certification as a requirement. In the S2S flow, the merchant has more control but will need to manage parts of the transaction flow such as 3DSecure and the construction of the ACS redirection...",
    "path": "/docs/others/"
  },
  {
    "title": "NETS QR Integration Guide",
    "description": "NETS QR API allows you to collect payment on any smart devices like tablets and mobile phones to through real-time generation of QR codes...",
    "path": "/docs/qr/"
  }
]